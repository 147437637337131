<template>
    <div>
        <b-spinner v-show="loading" small type="grow" variant="success"/>
        <span v-show="!loading" class="d-flex align-items-center font-weight-bold" @click="handleClick">
            <feather-icon :color="color" icon="CheckCircleIcon" v-if="checked" class="mr-1" size="19"/>
            <feather-icon :color="color" icon="CircleIcon" v-else class="mr-1" size="19"/>
            <span :style="{color: color}">{{ label }}</span>
        </span>
    </div>
</template>
<script>
import { BSpinner } from 'bootstrap-vue';
export default {
    name: 'CheckBoxCustom',
    components: {
        BSpinner
    },
    props: {
        checked: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: ''
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
        }
    },
    computed: {
        color() {
            return this.checked ? '#388a66' : '#474f4a'
        }
    },
    methods: {
        handleClick() {
            this.$emit('change', !this.checked)
        }
    }
}
</script>
<style scoped>

</style>